import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/bootsite/tippyjs.bootcss.com/website/src/components/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Instance methods`}</h3>
    <p>{`Methods on instances allow you to control the tippy programmatically. See the
`}<a parentName="p" {...{
        "href": "/tippy-instance/"
      }}>{`Tippy Instance`}</a>{` page for details on accessing an instance.`}</p>
    <h4>{`show`}</h4>
    <p>{`Programmatically show the tippy at any time:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Default`}</span>{`
`}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 0ms transition duration`}</span></code></pre></div>
    <h4>{`hide`}</h4>
    <p>{`Programmatically hide the tippy at any time:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`hide`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Default`}</span>{`
`}<span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`hide`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 500ms transition duration`}</span></code></pre></div>
    <h4>{`disable`}</h4>
    <p>{`Temporarily prevent a tippy from showing or hiding:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`disable`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`enable`}</h4>
    <p>{`Re-enable a tippy:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`enable`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`setProps`}</h4>
    <p>{`You can update any prop after the instance has been created. Pass an object of
new props in:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`setProps`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  arrow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  animation`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`scale`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`setContent`}</h4>
    <p>{`Updating the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`content`}</code>{` prop has its own method as a shortcut:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`setContent`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`New content`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`destroy`}</h4>
    <p>{`To permanently destroy and clean up the instance, use this method:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`instance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`destroy`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_tippy`}</code>{` property is deleted from the reference element upon destruction.`}</p>
    <h3>{`Static methods`}</h3>
    <p>{`Static methods belong to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy`}</code>{` module for global behavior.`}</p>
    <h4>{`setDefaultProps`}</h4>
    <p>{`Set the default props for each new instance:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token object"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token func method"
          }}>{`setDefaultProps`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Props`}</span>{`
`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Access the current default props`}</span>{`
`}<span parentName="code" {...{
            "className": "token object"
          }}>{`tippy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token access property"
          }}>{`defaultProps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h4>{`hideAll`}</h4>
    <p>{`Hide all visible tippies on the document:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token module keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`hideAll`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token module keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span>{`tippy.js`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Use each tippy's own duration`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`hideAll`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Hide them all instantly`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`hideAll`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`duration`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Hide them all except a particular one`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`hideAll`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`exclude`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` tippyInstance`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`hideAll`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`{`}</span>{`exclude`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` referenceElement`}<span parentName="code" {...{
            "className": "token block"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token block"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`In the CDN (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iife`}</code>{`) version, it's available as `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tippy.hideAll()`}</code>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      